import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import api from '../api/api';

const GoogleSignIn: React.FC = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLoginSuccess = async (tokenResponse: any) => {
        try {
            const token = tokenResponse.access_token;
            const backendResponse = await api.post('/auth/google', { token });
            localStorage.setItem('username', backendResponse.data.username);
            login(backendResponse.data.role); // Call the login function from AuthContext
            navigate('/doctors'); // Navigate to the chat page
        } catch (error) {
            console.error('Google login failed:', error);
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: handleLoginSuccess,
        onError: (error) => console.error('Login Failed:', error),
    });

    return (
        <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            onClick={() => googleLogin()}
            sx={{ backgroundColor: '#db4437', color: 'white', textTransform: 'none', minWidth: 40, minHeight: 40 }}
        >
            Sign up with Google
        </Button>
    );
};

export default GoogleSignIn;
