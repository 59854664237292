// src/components/Login.tsx
import React, { ChangeEvent, useState, KeyboardEvent } from 'react';
import { useMediaQuery, Box, Button, TextField, Typography, Link, Container, Grid, Snackbar, Alert, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import api from '../api/api';
import { useAuth } from '../context/AuthContext';
import SocialSignUpButtons from './SocialSignUpButtons';
import '../../src/css/style.css';
import { AxiosError } from 'axios';
import { ErrorDetail } from '../types/errorDetail';

const Login: React.FC = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (!email || !password) {
      setError('Email and password are required');
      setSnackbarOpen(true);
      return;
    }

    const data = new URLSearchParams({
      username: email,
      password: password,
    });
    try {
      const response = await api.post('/token', data.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });
      localStorage.setItem("username", response.data.username);
      login(response.data.role); // Call the login function with the user role
    } catch (error) {
      console.error('Login failed:', error);
      const axiosError = error as AxiosError<ErrorDetail>;
      if (axiosError.response) {
        setError(axiosError.response.data.detail);
      } else {
        setError("Request failed");
        console.error('No response received', axiosError.request);
      }
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="xl" className='main_container' sx={{ display: 'flex', minHeight: '100vh', minWidth: '0' }}>
      <Grid container spacing={isSmallScreen ? 0 : 3} sx={{ flex: 1 }}>
        <Grid item xs={12} md={5} sx={{
          backgroundColor: '#b3dee1', 
          display: isSmallScreen ? 'flex' : 'flex',
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          padding: isSmallScreen ? 2 : 0
        }}>
          <Box 
          className='left_coloumn'
            width="100%" 
            height="100%" 
          >
            <Box
              component="img"
              src="/globifit-logo.svg"
              alt="GlobiFit Logo"
              sx={{ width: isSmallScreen ? '150px' : '250px', marginBottom: '20px' }}
            />
            <Box marginTop="5%" paddingBottom="10%">
              
              <Typography className='login_main_txt' variant="h4">
                Worldwide Wellness
              </Typography>
              <Typography className='left_subtitle' variant="subtitle1" sx={{ fontWeight: 'bold', color: '#000000', textAlign: isSmallScreen ? 'center' : 'left' }}>
                Our Global Healthcare Community
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className='right_coloumn' item xs={12} md={7}>
          <Box width="100%" height="20%" marginBottom={'0'} className='topholder' >
            <Box width="100%" textAlign={isSmallScreen ? "center" : "right"} mt={2} display="flex" flexDirection="column" >
              <Typography className='external_txt' variant="body2">
                Not a member? <Link href="/register" className='main_link_top'>Register</Link>
              </Typography>
            </Box>
          </Box>
          <Box className='login_box' width={isSmallScreen ? "100%" : "75%"} >
            <Box textAlign={'center'}>
              <Typography variant="h5" className='main_title' gutterBottom sx={{ fontWeight: 'bold', marginY: '16px', color: '#000000' }}>
                Globi.Fit Login
              </Typography>
              <Typography className='main_subtitle'>Access Your Fitness Journey</Typography>
            </Box>
            
            
            
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={email}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              variant="outlined"
              margin="normal"
              error={!!error && !email}
              helperText={!!error && !email ? "Email is required" : ""}
            />
            <TextField
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              variant="outlined"
              margin="normal"
              error={!!error && !password}
              helperText={!!error && !password ? "Password is required" : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box my={1} className='submit_box'>
              <Button 
                className='submit_btn'
                variant="contained" 
                sx={{ marginY: 2, color: "white", backgroundColor: "#03939d" }} 
                onClick={handleLogin}
                disabled={!password || !email}>
                Sign In
              </Button>
              <Box textAlign="right">
                <Link href="/forgotPassword" className='forgot_link' variant="body2">
                  Forgot Password?
                </Link>
              </Box>
            </Box>
            <Box my={3}>
              <Typography variant="body2" align="center" className='devider'>
                Or 
              </Typography>
            </Box>
            <Box className='social_links'>
              <SocialSignUpButtons />
            </Box>
            
          </Box>
          <Box my={2} className='btm_link_box'>
            <Link href="/terms-and-conditions">Terms & Conditions</Link>
            <Link href="/privacy-policy">Privacy Policy</Link>
          </Box>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
