import React, { ChangeEvent, useState, FormEvent } from 'react';
import { Box, Button, Container, Grid, Link, TextField, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import SocialSignUpButtons from './SocialSignUpButtons';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [error, setError] = useState<string>('');
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
    if (!emailRegex.test(value)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handleForgotPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format');
      return;
    }

    try {
      const response = await api.post('/forgot-password', { user_email: email });
      console.log(response.data);
      navigate('/login'); // Redirect to login page
    } catch (error) {
      console.error('Password reset failed:', error);
      setError('Password reset failed. Please try again.');
    }
  };

  return (
    <Container maxWidth="xl" className='main_container'  sx={{ display: 'flex', minHeight: '100vh', minWidth: '0' }}>
      <Grid container spacing={isSmallScreen ? 0 : 3} sx={{ flex: 1 }}>
        <Grid item xs={12} md={5} sx={{
          backgroundColor: '#b3dee1', 
          display: isSmallScreen ? 'flex' : 'flex',
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          padding: isSmallScreen ? 2 : 0
        }}>
          <Box 
          className='left_coloumn'
            width="100%" 
            height="100%" 
          >
            <Box
              component="img"
              src="/globifit-logo.svg"
              alt="GlobiFit Logo"
              sx={{ width: isSmallScreen ? '150px' : '250px', marginBottom: '20px' }}
            />
            <Box marginTop="5%" paddingBottom="10%">
              
              <Typography className='login_main_txt' variant="h4">
                Worldwide Wellness
              </Typography>
              <Typography className='left_subtitle' variant="subtitle1" sx={{ fontWeight: 'bold', color: '#000000', textAlign: isSmallScreen ? 'center' : 'left' }}>
                Our Global Healthcare Community
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={7} className='right_coloumn'>
          <Box width="100%" className='topholder' height="20%">
            <Box width="100%" textAlign={isSmallScreen ? "center" : "right"} mt={2} display="flex" flexDirection="column" >
              <Typography variant="body2" className='external_txt'>
                Return to <Link href="/login" className='main_link_top'>Sign In</Link>
              </Typography>
            </Box>
          </Box>
          <Box className='login_box' width={isSmallScreen ? "100%" : "75%"} height="auto">
            <Box textAlign={'center'}>
              <Typography variant="h5" className='main_title' gutterBottom sx={{ fontWeight: 'bold', marginY: '16px', color: '#000000', textAlign: isSmallScreen ? 'center' : 'center' }}>
                Forgot Password?
              </Typography>
              <Typography className='main_subtitle forgot'>
                Enter the email address associated with your account.
              </Typography>
            </Box>
            <form id="forgotForm" onSubmit={handleForgotPassword}>
              <TextField
                fullWidth
                id="email"
                label="Email"
                type="email"
                variant="outlined"
                margin="normal"
                value={email}
                onChange={handleInputChange}
                error={!!emailError}
                helperText={emailError}
                required
              />
              <Box my={1} className='submit_box'>
                <Button
                  className='submit_btn'
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: '#0097a7', color: 'white', marginTop: 2 }}
                  disabled={!!emailError || email === ''}
                >
                  Reset Password
                </Button>
              </Box>
            </form>
            <Box my={3}>
              <Typography variant="body2" align="center" className='devider'>
                Or 
              </Typography>
            </Box>
            <Box className='social_links'>
              <SocialSignUpButtons />
            </Box>
            {error && (
              <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
                {error}
              </Typography>
            )}
          </Box>
          <Box my={2} className='btm_link_box'>
            <Link href="/terms-and-conditions">Terms & Conditions</Link>
            <Link href="/privacy-policy">Privacy Policy</Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;
