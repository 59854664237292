import React, { useState } from 'react';
import { useMediaQuery, Box, Typography, Container, Grid, Snackbar, Alert, Link } from '@mui/material';
import '../../src/css/style.css';

const PrivacyPolicyPage: React.FC = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="xl" className='main_container' sx={{ display: 'flex', minHeight: '100vh', minWidth: '0' }}>
      <Grid container spacing={isSmallScreen ? 0 : 3} sx={{ flex: 1 }}>
        <Grid item xs={12} md={5} sx={{
          backgroundColor: '#b3dee1', 
          display: 'flex',
          flexDirection: 'column', 
          alignItems: 'center', 
          padding: isSmallScreen ? 2 : 0
        }}>
          <Box 
          className='left_coloumn fixed_content'
            width="100%" 
            height="100%" 
          >
            <Box
              component="img"
              src="/globifit-logo.svg"
              alt="GlobiFit Logo"
              sx={{ width: isSmallScreen ? '150px' : '250px', marginBottom: '20px' }}
            />
            <Box marginTop="5%" paddingBottom="10%">
              
              <Typography className='login_main_txt' variant="h4">
                Worldwide Wellness
              </Typography>
              <Typography className='left_subtitle' variant="subtitle1" sx={{ fontWeight: 'bold', color: '#000000', textAlign: isSmallScreen ? 'center' : 'left' }}>
                Our Global Healthcare Community
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className='right_column single_content' item xs={12} md={7} sx={{ padding: 4 }}>
          <Box>
            <Typography variant="h3" component="h1" className="py-3">Privacy Policy</Typography>
            <Typography variant="h4" component="h2">Introduction</Typography>
            <Typography variant="body1" component="p">
              Globi.fit ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website and Services.
            </Typography>
            <Typography variant="h5" component="h3">Information We Collect</Typography>
            <Typography variant="body1" component="p"><strong>Personal Information:</strong> We collect personal information such as your mobile number and email address when you use our Services. This information is used for marketing purposes, including sending you promotional materials and updates about our Services.</Typography>
            <Typography variant="body1" component="p"><strong>Payment Information:</strong> Payments are processed through Razorpay and Stripe. We do not store your payment information. For details on how your payment information is handled, please refer to the privacy policies of Razorpay and Stripe:</Typography>
            <ul className="px-5">
              <li>Razorpay Privacy Policy</li>
              <li>Stripe Privacy Policy</li>
            </ul>
            <Typography variant="h5" component="h3">Use of Information</Typography>
            <Typography variant="body1" component="p"><strong>Marketing:</strong> We use your contact information to send you promotional materials and updates about our Services. You may opt-out of receiving marketing communications at any time by following the unsubscribe instructions provided in the communication.</Typography>
            <Typography variant="body1" component="p"><strong>Service Improvement:</strong> We may use your information to improve our Services, conduct research and analysis, and provide a better user experience.</Typography>
            <Typography variant="h5" component="h3">Third-Party Services</Typography>
            <Typography variant="body1" component="p">We use third-party services such as Razorpay and Stripe for payment processing. These services have their own privacy policies and terms of service, which we recommend you review. We are not responsible for the privacy practices or the content of these third-party services.</Typography>
            <Typography variant="h4" component="h2">Cookies and Tracking</Typography>
            <Typography variant="body1" component="p">We use cookies and similar tracking technologies to enhance your experience on our Site. These cookies are used to track your activity on our Site only and not on other websites. You can manage your cookie preferences through your browser settings.</Typography>
            <Typography variant="h5" component="h3">Data Security</Typography>
            <Typography variant="body1" component="p"><strong>SSL Certificate:</strong> We use SSL certificates to encrypt data transferred from our Site to our servers, ensuring that your information is securely transmitted.</Typography>
            <Typography variant="body1" component="p"><strong>AWS Services:</strong> We use Amazon Web Services (AWS) to store and protect your data. AWS is responsible for securing data once it reaches their servers. For more information about AWS security, please visit the AWS Security Center.</Typography>
            <Typography variant="h5" component="h3">Data Retention</Typography>
            <Typography variant="body1" component="p">We will retain your personal information for as long as necessary to provide our Services, comply with legal obligations, resolve disputes, and enforce our agreements.</Typography>
            <Typography variant="h5" component="h3">Contact Us</Typography>
            <Typography variant="body1" component="p">If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at <Link href="mailto:contact@globi.fit">contact@globi.fit</Link>.</Typography>
            <Typography variant="h5" component="h3">Changes to Privacy Policy</Typography>
            <Typography variant="body1" component="p">We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page, and we encourage you to review our Privacy Policy periodically. Your continued use of our Site and Services following the posting of changes will constitute your acceptance of such changes.</Typography>
            <Typography variant="body1" component="p">By using our Site and Services, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</Typography>
          </Box>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PrivacyPolicyPage;
