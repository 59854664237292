import axios from 'axios';
import { setToken, setTokenExpiration } from '../util/auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8000",
});

api.interceptors.response.use(
    (response) => {
      // Check if the response contains a token
      if (response.data.access_token) {
        setToken(response.data.access_token);
        setTokenExpiration(45*60)
        // Remove the token from the response data
        delete response.data.token;
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );


export default api;
