// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme, Box } from '@mui/material';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ChatPage from './components/ChatPage';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './util/PrivateRoute';
import AdminRoute from './util/AdminRoute';
import PublicRoute from './util/PublicRoute';
import PasswordReset from './components/PasswordReset';
import TermsPage from './components/TermsPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import AdminPanel from './components/admin/AdminDashboard';
import DoctorListingPage from './components/DoctorListingPage';
import { DoctorProvider } from './context/DoctorContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#b3dee1',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#ffffff',
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box display="flex" flexDirection="column" height="100%" width="100%">
        <Router>
          <AuthProvider>
            <DoctorProvider>
              <Routes>
                <Route path="/" element={<PublicRoute element={<Login />} />} />
                <Route path="/login" element={<PublicRoute element={<Login />} />} />
                <Route path="/register" element={<PublicRoute element={<Register />} />} />
                <Route path="/forgotPassword" element={<PublicRoute element={<ForgotPassword />} />} />
                <Route path="/doctors" element={<PrivateRoute element={<DoctorListingPage />} />} />
                <Route path="/chat" element={<PrivateRoute element={<ChatPage />} />} />
                <Route path="/password-reset" element={<PublicRoute element={<PasswordReset />} />} />
                <Route path="/terms-and-conditions" element={<TermsPage />}/>
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/admin" element={<AdminRoute element={<AdminPanel />} />} /> {/* Use AdminRoute here */}
                <Route path="*" element={<PublicRoute element={<Login />} />} />
              </Routes>
            </DoctorProvider>  
          </AuthProvider>
        </Router>
      </Box>
    </ThemeProvider>
  );
};

export default App;
