import React, { useState } from 'react';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { MessageModel, LikeStatusUpdate } from '../types/message';
import protectedApi from '../api/protectedApi';
import { UserFeedbackEnum } from '../types/enums';

interface ChatMessageProps {
  message: MessageModel;
  isCurrentUser: boolean;
  isSelected: boolean;
  sender: string | null;
  onClick: () => void;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message, isCurrentUser, isSelected, sender, onClick }) => {
  const [userFeedback, setUserFeedback] = useState<UserFeedbackEnum>(message?.user_feedback ? message?.user_feedback : UserFeedbackEnum.Neutral);

  const handleLike = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const newStatus = userFeedback === UserFeedbackEnum.Like ? UserFeedbackEnum.Neutral : UserFeedbackEnum.Like;
    try {
      const request: LikeStatusUpdate = {
        like_status: newStatus
      };
      await protectedApi.patch(`/messages/${message?.message_id}/like`, request);
      setUserFeedback(newStatus);
    } catch (error) {
      console.error('Error liking message:', error);
    }
  };

  const handleDislike = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const newStatus = userFeedback === UserFeedbackEnum.Dislike ? UserFeedbackEnum.Neutral : UserFeedbackEnum.Dislike;
    try {
      const request: LikeStatusUpdate = {
        like_status: newStatus
      };
      await protectedApi.patch(`/messages/${message?.message_id}/like`, request);
      setUserFeedback(newStatus);
    } catch (error) {
      console.error('Error disliking message:', error);
    }
  };

  return (
    <Box
      sx={{
        alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
        marginBottom: '10px',
        maxWidth: '75%',
      }}
    >
      <Typography variant="body2" color="textSecondary" sx={{ textAlign: isCurrentUser ? 'right' : 'left' }}>
        {sender}
      </Typography>
      <Paper
        onClick={onClick}
        sx={{
          backgroundColor: isCurrentUser ? '#e1ffc7' : '#fff',
          borderRadius: '10px',
          padding: '10px',
          cursor: 'pointer',
        }}
      >
        <Box sx={{ whiteSpace: 'pre-wrap' }}>
          <Typography variant="body1">{message?.content}</Typography>
        </Box>
        {!isCurrentUser && (
          <Box display="flex" justifyContent="flex-start" mt={1}>
            <IconButton
              size="small"
              onClick={handleLike}
              sx={{ padding: 0, marginRight: 1, color: userFeedback === UserFeedbackEnum.Like ? 'blue' : 'inherit' }}
            >
              <ThumbUpIcon sx={{ fontSize: 16 }} />
            </IconButton>
            <IconButton
              size="small"
              onClick={handleDislike}
              sx={{ padding: 0, color: userFeedback === UserFeedbackEnum.Dislike ? 'red' : 'inherit' }}
            >
              <ThumbDownIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        )}
      </Paper>
      {isSelected && (
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{ textAlign: isCurrentUser ? 'right' : 'left', marginTop: '5px' }}
        >
          {new Date(message?.sent_at).toLocaleString()}
        </Typography>
      )}
    </Box>
  );
};

export default ChatMessage;
