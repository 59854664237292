export enum SenderTypeEnum {
    User = "user",
    Assistant = "assistant"
}

export enum UserFeedbackEnum {
    Like = "like",
    Dislike = "dislike",
    Neutral = "neutral"
}

export enum SubscriptionTypeEnum {
    Free = "free",
    Premium = "premium"
}
