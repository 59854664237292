import axios from 'axios';
import { getToken, isTokenExpired, removeToken, removeTokenExpiration } from '../util/auth';
import api from './api';
import { useAuth } from '../context/AuthContext';
import { TokenExpiredError } from '../types/errorDetail';

const protectedApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8000",
});

protectedApi.interceptors.request.use(async (config) => {

  const token = getToken();
  if(isTokenExpired()) {
    try {
      const response = await api.post('/renew',null,{
        headers: {
          Authorization:`Bearer ${token}`,
        }});
      } catch (renewError) {
        console.log(`Error in renew ${renewError}`)
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        const error: TokenExpiredError = new TokenExpiredError("Token expired");
        return Promise.reject(error)
      }
  }  
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  
  return config;
});

export default protectedApi;