import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Container, Box, Grid, Link, useMediaQuery } from '@mui/material';
import api from '../api/api';

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await api.post('/password-reset', { token, new_password: newPassword });
      setMessage(response.data.message);
      navigate('/login');
    } catch (error) {
      setMessage('Error resetting password.');
    }
  };

  return (
    <Container maxWidth="xl" sx={{ display: 'flex', minHeight: '100vh', minWidth: '0' }}>
      <Grid container spacing={isSmallScreen ? 0 : 3} sx={{ flex: 1 }}>
        <Grid item xs={12} md={5} sx={{
          backgroundColor: '#b3dee1',
          display: isSmallScreen ? 'none' : 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: isSmallScreen ? 2 : 0
        }}>
          <Box
            width="100%"
            height="100%"
            paddingTop={isSmallScreen ? "5%" : "10%"}
            marginLeft={isSmallScreen ? 0 : "15%"}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: isSmallScreen ? 'center' : 'flex-start' }}
          >
            <Box
              component="img"
              src="/globifit-logo.svg"
              alt="GlobiFit Logo"
              sx={{ width: isSmallScreen ? '150px' : '250px', marginBottom: '20px' }}
            />
            <Box marginTop="15%">
              <Typography variant="body1" sx={{ color: '#6a7575', textAlign: isSmallScreen ? 'center' : 'left' }}>
                Don't worry,
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '16px', color: '#000000', textAlign: isSmallScreen ? 'center' : 'left' }}>
                We are here to help you recover your password.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: isSmallScreen ? 2 : 4, minHeight: 0, minWidth: 0 }}>
          <Box width="100%" height="20%">
            <Box width="100%" textAlign={isSmallScreen ? "center" : "right"} mt={2} display="flex" flexDirection="column" height="20%" paddingTop="24px">
              <Typography variant="body2">
                Return to <Link href="/login">Sign In</Link>
              </Typography>
            </Box>
          </Box>
          <Box width={isSmallScreen ? "100%" : "75%"} height="80%">
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginY: '16px', color: '#000000', textAlign: isSmallScreen ? 'center' : 'left' }}>
              Reset Your Password
            </Typography>
            {!token || message.includes('Invalid') ? (
              <Typography variant="body1">{message}</Typography>
            ) : (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="New Password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  variant="outlined"
                  margin="normal"
                />
                <Box sx={{ display: 'flex', justifyContent: isSmallScreen ? 'center' : 'flex-start', width: '100%' }}>
                  <Button type="submit" variant="contained" sx={{ backgroundColor: '#0097a7', color: 'white', marginTop: 2 }}>
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
            {message && !message.includes('Invalid') && (
              <Typography variant="body1">{message}</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PasswordReset;
