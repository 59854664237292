import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useMediaQuery, Box, Container, Grid, Typography, CircularProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DoctorCard from './DoctorCard';
import protectedApi from '../api/protectedApi';
import { DoctorModel } from '../types/doctor';
import { useDoctor } from '../context/DoctorContext';
import '../../src/css/style.css';
import { useAuth } from '../context/AuthContext';
import { TokenExpiredError } from '../types/errorDetail';

const DoctorListingPage: React.FC = () => {
  const { logout } = useAuth();
  const [doctors, setDoctors] = useState<DoctorModel[]>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const observer = useRef<IntersectionObserver | null>(null);

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const { setSelectedDoctor } = useDoctor();

  useEffect(() => {
    fetchDoctors();
  }, [page]);

  const fetchDoctors = async () => {
    setLoading(true);
    try {
      const response = await protectedApi.get('/assistants', { params: { page, rows: 10 } });
      setDoctors((prevDoctors) => [...prevDoctors, ...response.data]);
      setHasMore(response.data.length > 0);
      setLoading(false);
    } catch (error) {
      if (error instanceof TokenExpiredError) {
        console.error('Error 401: Unauthorized. Logging out...');
        logout();  
      }
      console.error('Error fetching doctors:', error);
      setLoading(false);
    }
  };

  const handleConnect = (doctor: DoctorModel) => {
    setSelectedDoctor(doctor);
    navigate('/chat');
  };

  const lastDoctorElementRef = useCallback((node: HTMLDivElement) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const handleLogout = () => {
    logout();
  };

  return (
    <Container maxWidth="xl" className='main_container' sx={{ display: 'flex', minHeight: '100vh', minWidth: '0', position: 'relative' }}>
      <Grid container spacing={isSmallScreen ? 0 : 3} sx={{ flex: 1 }}>
        <Grid item xs={12} md={5} sx={{
          backgroundColor: '#b3dee1', 
          display: isSmallScreen ? 'flex' : 'flex',
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          padding: isSmallScreen ? 2 : 0
        }}>
          <Box className='left_coloumn fixed_content' width="100%" height="100%">
            <Box
              component="img"
              src="/globifit-logo.svg"
              alt="GlobiFit Logo"
              sx={{ width: isSmallScreen ? '150px' : '250px', marginBottom: '20px' }}
            />
            <Box marginTop="5%" paddingBottom="10%">
              <Typography className='login_main_txt' variant="h4">
                Worldwide Wellness
              </Typography>
              <Typography className='left_subtitle' variant="subtitle1" sx={{ fontWeight: 'bold', color: '#000000', textAlign: isSmallScreen ? 'center' : 'left' }}>
                Our Global Healthcare Community
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid className='right_coloumn' item xs={12} md={7}>
          <Box width="100%" height="20%" className='topholder'>
            <Box width="100%" textAlign={isSmallScreen ? "center" : "left"} mt={2} display="flex" justifyContent="space-between" flexDirection="row">
              <Typography variant="body2" className='external_txt2'>
                AI Specialist Doctors with Disease
              </Typography>
              <Button className="main_link_top" onClick={handleLogout} >Logout</Button>
            </Box>
          </Box>
          
          <Box className='doctor_list NewList'>
            {doctors.map((doctor, index) => {
              if (doctors.length === index + 1) {
                return (
                  <Box ref={lastDoctorElementRef} key={doctor.assistant_id} mb={2}>
                    <DoctorCard
                      image_link={doctor.image_link}
                      name={doctor.name}
                      speciality={doctor.speciality}
                      description={doctor.description}
                      onConnect={() => handleConnect(doctor)}
                    />
                  </Box>
                );
              } else {
                return (
                  <Box key={doctor.assistant_id} mb={2} className='NewList'>
                    <DoctorCard
                      image_link={doctor.image_link}
                      name={doctor.name}
                      speciality={doctor.speciality}
                      description={doctor.description}
                      onConnect={() => handleConnect(doctor)}
                    />
                  </Box>
                );
              }
            })}
            {loading && (
              <Box display="flex" justifyContent="justify" mt={2}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DoctorListingPage;
