import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react';
import { Box, TextField, Button, Typography, AppBar, Toolbar, CircularProgress, Avatar, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ChatMessage from './ChatMessage'; // Import the new ChatMessage component
import { MessageModel } from '../types/message'; // Import the Message interface
import '../css/scrollbar.css'; // Import the custom scrollbar styles
import { SenderTypeEnum } from '../types/enums';
import { DoctorModel } from '../types/doctor';
import { ConversationModel } from '../types/conversation';
import { useDoctor } from '../context/DoctorContext';

interface ChatWindowProps {
  conversation: ConversationModel | null;
  messages: MessageModel[];
  username: string | null;
  doctor: DoctorModel | null;
  onSendMessage: (messageContent: string) => Promise<void>;
  onLogout: () => void;
  onBack: () => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ conversation, messages, username, doctor, onSendMessage, onLogout, onBack }) => {
  const { setSelectedDoctor } = useDoctor();
  const navigate = useNavigate();
  const [newMessage, setNewMessage] = useState('');
  const [selectedMessageIndex, setSelectedMessageIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '' || loading) return;
    setLoading(true);
    setNewMessage('');
    await onSendMessage(newMessage);
    setLoading(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && !loading) { // Allows for Shift+Enter to break line instead of sending
      e.preventDefault(); // Prevent the default action to avoid newline in input
      handleSendMessage();
    }
  };

  const handleMessageClick = (index: number) => {
    setSelectedMessageIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const displayUsername = username ?? 'UserNotFound';

  return (
    <Box display="flex" flexDirection="column" height="100vh" position="relative" width="100%">
      {/* Chat Title */}
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: "#03939d", display: 'flex', alignItems: 'center' }}>
          <IconButton edge="start" color="inherit" onClick={onBack} sx={{ marginRight: 2, color: 'white' }}>
            <ArrowBackIcon />
          </IconButton>
          {doctor && (
            <>
              <Avatar src={doctor.image_link} alt={doctor.name} sx={{ marginRight: 2 }} />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6" sx={{ color: "white" }}>
                  {doctor.name}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: "white" }}>
                  {doctor.speciality}
                </Typography>
              </Box>
            </>
          )}
          <Button variant="outlined" onClick={onLogout} sx={{ color: "white" }}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {/* Messages */}
      <Box flexGrow={1} overflow="auto" p={2} display="flex" flexDirection="column">
        {messages.map((message, index) => (
          <ChatMessage
            key={index}
            message={message}
            isCurrentUser={message?.sender_type === SenderTypeEnum.User}
            isSelected={selectedMessageIndex === index}
            sender={message?.sender_type === SenderTypeEnum.User ? displayUsername : doctor ? doctor.name : null}
            onClick={() => handleMessageClick(index)}
          />
        ))}
        <div ref={messagesEndRef} />
      </Box>
      {/* Input */}
      <Box p={2} borderTop={1} borderColor="grey.300" bgcolor="white" position="sticky" bottom={0}>
        <Box display="flex">
          <TextField
            fullWidth
            value={newMessage}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Type a message"
            variant="outlined"
          />
          <Button 
            variant="contained" 
            onClick={handleSendMessage} 
            sx={{ marginLeft: 1, backgroundColor: "#03939d", color: "white" }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : 'Send'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatWindow;
