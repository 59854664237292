import React, { useState } from 'react';
import { useMediaQuery, Box, Typography, Container, Grid, Snackbar, Alert, Link } from '@mui/material';
import '../../src/css/style.css';

const TermsPage: React.FC = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="xl" className='main_container' sx={{ display: 'flex', minHeight: '100vh', minWidth: '0' }}>
      <Grid container spacing={isSmallScreen ? 0 : 3} sx={{ flex: 1 }}>
        <Grid item xs={12} md={5} sx={{
          backgroundColor: '#b3dee1', 
          display: 'flex',
          flexDirection: 'column', 
          alignItems: 'center', 
          padding: isSmallScreen ? 2 : 0
        }}>
          <Box 
          className='left_coloumn fixed_content'
            width="100%" 
            height="100%" 
          >
            <Box
              component="img"
              src="/globifit-logo.svg"
              alt="GlobiFit Logo"
              sx={{ width: isSmallScreen ? '150px' : '250px', marginBottom: '20px' }}
            />
            <Box marginTop="5%" paddingBottom="10%">
              
              <Typography className='login_main_txt' variant="h4">
                Worldwide Wellness
              </Typography>
              <Typography className='left_subtitle' variant="subtitle1" sx={{ fontWeight: 'bold', color: '#000000', textAlign: isSmallScreen ? 'center' : 'left' }}>
                Our Global Healthcare Community
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className='right_column single_content' item xs={12} md={7} sx={{ padding: 4 }}>
          <Box>
            <Typography variant="h3" component="h1" className="py-3">Terms and Conditions</Typography>
            <Typography variant="h4" component="h2">Introduction</Typography>
            <Typography variant="body1" component="p">
              Welcome to Globi.fit! These Terms and Conditions ("Terms") govern your access to and use of our website located at www.globi.fit (the "Site") and the services provided by Globi.fit ("Services"). By accessing or using our Site and Services, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, please do not use our Site or Services.
            </Typography>
            <Typography variant="h5" component="h3">Company Information</Typography>
            <Typography variant="body1" component="p">
              <strong>Legal Name:</strong> Globi.fit<br />
              <strong>Registered Address:</strong> B92, Sushant Lok 1, Gurugram 122002<br />
              <strong>Contact Information:</strong> <Link href="mailto:contact@globi.fit">contact@globi.fit</Link>
            </Typography>
            <Typography variant="h5" component="h3">Services</Typography>
            <Typography variant="body1" component="p">
              Globi.fit provides AI-powered consultation services focused on diet and lifestyle. Our Services are designed to offer general guidance and recommendations. We do not provide medical treatments, medications, or home remedies. In the future, we may add an e-commerce store and other allied services.
            </Typography>
            <Typography variant="h5" component="h3">User Responsibilities</Typography>
            <Typography variant="body1" component="p">
              Accuracy of Information: You agree to provide accurate, current, and complete information when creating an account or using our Services. You are responsible for maintaining the accuracy of the information you provide.
            </Typography>
            <Typography variant="body1" component="p"><strong>Prohibited Conduct:</strong> You agree not to use our Site or Services for any unlawful purposes or to engage in any activity that would constitute a violation of any applicable law or regulation. This includes, but is not limited to, the following:</Typography>
            <ul className="px-5">
              <li>Engaging in any fraudulent or deceptive practices</li>
              <li>Distributing any harmful or malicious content</li>
              <li>Infringing on the intellectual property rights of others</li>
              <li>Engaging in any activity that disrupts or interferes with the functioning of our Site or Services</li>
            </ul>
            <Typography variant="body1" component="p"><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account information, including your username and password, and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</Typography>
            <Typography variant="body1" component="p"><strong>Respectful Interaction:</strong> You agree to interact respectfully with our staff and other users of our Services. Harassment, abuse, or any form of inappropriate behavior will not be tolerated.</Typography>
            <Typography variant="h5" component="h3">Payments</Typography>
            <Typography variant="body1" component="p">
              We use Razorpay and Stripe as our payment gateways. By using our Services, you agree to the terms and conditions of Razorpay and Stripe, which can be found on their respective websites:
            </Typography>
            <Typography variant="h6" component="h4">Razorpay Terms and Conditions</Typography>
            <Typography variant="h6" component="h4">Stripe Terms and Conditions</Typography>
            <Typography variant="body1" component="p">Any disputes regarding payment will be handled by the respective payment gateway. Globi.fit is not responsible for any issues related to payment processing.</Typography>
            <Typography variant="h5" component="h3">Limitation of Liability</Typography>
            <Typography variant="body1" component="p">
              AI Consultation: Our Services use AI to provide diet and lifestyle consultations. The information provided is for general informational purposes only and should not be construed as professional medical advice. You should always seek the advice of a qualified healthcare provider with any questions you may have regarding a medical condition.
            </Typography>
            <Typography variant="body1" component="p">Service Limitations: We do not guarantee that the Services will meet your specific requirements or that the Services will be uninterrupted, timely, secure, or error-free. We reserve the right to modify or discontinue the Services at any time without notice.</Typography>
            <Typography variant="body1" component="p">No Liability for Damages: To the fullest extent permitted by law, Globi.fit will not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from your use of our Site or Services, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses.</Typography>
            <Typography variant="h5" component="h3">Dispute Resolution</Typography>
            <Typography variant="body1" component="p">Any disputes arising from or related to the use of our Site or Services will be subject to the exclusive jurisdiction of the courts of Haryana, India.</Typography>
            <Typography variant="h5" component="h3">Changes to Terms</Typography>
            <Typography variant="body1" component="p">
              We reserve the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting on our Site. Your continued use of our Site and Services following the posting of changes will constitute your acceptance of such changes.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TermsPage;
