export const getToken = () => {
    return localStorage.getItem('accessToken');
  };

export const setToken = (token: string ) => {
    return localStorage.setItem('accessToken', token) 
  }

export const removeToken = () => {
    return localStorage.removeItem('accessToken') 
}

export const getTokenExpiration = () => {
    const expiration = localStorage.getItem('tokenExpiration');
    return expiration ? new Date(expiration) : null;
};

export const setTokenExpiration = (expires_in: number) => {
    const tokenExpiration: Date = new Date(new Date().getTime() + expires_in * 1000)
    localStorage.setItem('tokenExpiration', tokenExpiration.toISOString());
};


export const removeTokenExpiration = () => {
    return localStorage.removeItem('tokenExpiration') 
}

export const isTokenExpired = () => {
    const expiration = getTokenExpiration();
    if (!expiration) {
        return true;     
    }
    return new Date() > expiration;
};