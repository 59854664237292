import React from 'react';
import { Grid, Button, useMediaQuery } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleSignIn from '../otherLogin/GoogleSignIn';
import { GoogleOAuthProvider } from '@react-oauth/google';

const SocialSignUpButtons: React.FC = () => {
  const googleClientId : string = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? "";
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Grid container spacing={1} justifyContent={isSmallScreen ? "center" : "left"}>
      <Grid item>
        <GoogleOAuthProvider clientId={googleClientId}>
          <GoogleSignIn />
        </GoogleOAuthProvider>
      </Grid>
      {/* <Grid item>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#3b5998', color: 'white', minWidth: 40, minHeight: 40 }}
        >
          <FacebookIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#1da1f2', color: 'white', minWidth: 40, minHeight: 40 }}
        >
          <TwitterIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0077b5', color: 'white', minWidth: 40, minHeight: 40 }}
        >
          <LinkedInIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#000000', color: 'white', minWidth: 40, minHeight: 40 }}
        >
          <AppleIcon />
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default SocialSignUpButtons;
