import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import protectedApi from '../api/protectedApi';
import ChatWindow from './ChatWindow';
import { Container, Grid, useMediaQuery } from '@mui/material';
import { getToken, removeToken, removeTokenExpiration } from '../util/auth';
import { useAuth } from '../context/AuthContext';
import { MessageCreate, MessageModel } from '../types/message';
import { ConversationModel } from '../types/conversation';
import { SenderTypeEnum } from '../types/enums';
import { useDoctor } from '../context/DoctorContext';
import { TokenExpiredError } from '../types/errorDetail';

const ChatPage: React.FC = () => {
  const webSocketUrl: string = process.env.REACT_APP_BACKEND_WEBSOCKET_URL ?? "ws://localhost:8000/messages/ws/";
  const { selectedDoctor,setSelectedDoctor } = useDoctor();
  const [conversation, setConversation] = useState<ConversationModel | null>(null);
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const ws = useRef<WebSocket | null>(null);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isLoggingOutRef = useRef(false);

  const connectWebSocket = useCallback((conversationId: string) => {
    const token = getToken();
    ws.current = new WebSocket(`${webSocketUrl}${conversationId}?token=${token}`);

    ws.current.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.current.onmessage = (event) => {
      if (event.data !== conversationId) {
        const message: MessageModel = JSON.parse(event.data);
        setMessages((prevMessages) => [
          ...prevMessages.filter((msg) => msg.message_id !== message.message_id),
          message,
        ]);
      }
    };

    ws.current.onclose = () => {
      if (!isLoggingOutRef.current) {
        console.log('WebSocket closed, attempting to reconnect');
        setTimeout(() => connectWebSocket(conversationId), 3000);
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error', error);
      ws.current?.close();
    };
  }, [webSocketUrl]);

  const fetchChat = useCallback(async () => {
    const username = localStorage.getItem('username');
    if (username && selectedDoctor) {
      try {
        const conversationResponse = await protectedApi.get(`/conversations/${selectedDoctor?.assistant_id}`);
        const conversation: ConversationModel = conversationResponse.data;
        setConversation(conversation);

        const messagesResponse = await protectedApi.get(`/messages/${conversation.conversation_id}`);
        const fetchedMessages: MessageModel[] = messagesResponse.data;
        setMessages(fetchedMessages);
      } catch (error) {
        if (error instanceof TokenExpiredError) {
          console.error('Error 401: Unauthorized. Logging out...');
          logout();  
        }  
        console.error('Error fetching chat and messages:', error);
      }
    } else {
      console.error('Username not found in local storage or selected doctor is null');
      navigate('/doctors');
    }
  }, [selectedDoctor, navigate]);

  useEffect(() => {
    if (!selectedDoctor) {
      navigate('/doctors');
    } else {
      fetchChat();
    }
  }, [selectedDoctor, fetchChat, navigate]);

  useEffect(() => {
    if (conversation) {
      connectWebSocket(conversation.conversation_id);
    }

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [conversation, connectWebSocket]);

  const handleSendMessage = async (messageContent: string) => {
    if (conversation?.conversation_id === null) return;

    try {
      const messageCreate: MessageCreate = {
        conversation_id: conversation ? conversation.conversation_id : '',
        sender_type: SenderTypeEnum.User,
        content: messageContent,
        sent_at: new Date(),
      };
      await protectedApi.post(`/messages/${conversation?.conversation_id}`, messageCreate);
    } catch (error) {
      if (error instanceof TokenExpiredError) {
        console.error('Error 401: Unauthorized. Logging out...');
        logout();  
      }
      console.error('Error sending message:', error);
    }
  };

  const handleLogout = () => {
    isLoggingOutRef.current = true;
    if (ws.current) {
      ws.current.close();
    }
    logout();
  };

  const handleBackClick = () => {
    setSelectedDoctor(null);
    isLoggingOutRef.current = true;
    if (ws.current) {
      ws.current.close();
    }
    navigate('/doctors');
  };


  return (
    <Container className="main_container" maxWidth="xl" sx={{ display: 'flex', minHeight: '100vh', minWidth: '0', margin: '0', padding: '0 !important' }}>
      <Grid container spacing={isSmallScreen ? 0 : 3} sx={{ flex: 1 }}>
        <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <ChatWindow
            conversation={conversation}
            doctor={selectedDoctor}
            messages={messages}
            username={localStorage.getItem('username')}
            onSendMessage={handleSendMessage}
            onLogout={handleLogout}
            onBack={handleBackClick}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChatPage;
