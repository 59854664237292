import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface PublicRouteProps {
  element: React.ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const { isAuthenticated, role } = useAuth();
  return !isAuthenticated ? element : role==='admin' ? <Navigate to="/admin" />: <Navigate to="/doctors" />;
};

export default PublicRoute;
