// DoctorContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { DoctorModel } from '../types/doctor';

interface DoctorContextProps {
  selectedDoctor: DoctorModel | null;
  setSelectedDoctor: (doctor: DoctorModel | null) => void;
}

const DoctorContext = createContext<DoctorContextProps | undefined>(undefined);

export const DoctorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedDoctor, setSelectedDoctor] = useState<DoctorModel | null>(null);

  return (
    <DoctorContext.Provider value={{ selectedDoctor, setSelectedDoctor }}>
      {children}
    </DoctorContext.Provider>
  );
};

export const useDoctor = (): DoctorContextProps => {
  const context = useContext(DoctorContext);
  if (!context) {
    throw new Error('useDoctor must be used within a DoctorProvider');
  }
  return context;
};
