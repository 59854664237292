// DoctorCard.tsx
import React from 'react';
import { Card, CardContent, Typography, Button, Avatar, Box, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

interface DoctorCardProps {
  image_link: string;
  name: string;
  speciality: string;
  description: string;
  onConnect?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  showEditDelete?: boolean;
}

const DoctorCard: React.FC<DoctorCardProps> = ({
  image_link,
  name,
  speciality,
  description,
  onConnect,
  onEdit,
  onDelete,
  showEditDelete = false,
}) => {
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, borderRadius: 2, marginBottom: 2 }}>
      <Avatar src={image_link} alt={name} sx={{ marginRight: 2, width: 80, height: 80, borderRadius: '50%' }} />
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="h6" component="div">
          {name}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {speciality}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      {showEditDelete ? (
        <Box>
          <IconButton onClick={onEdit} aria-label="edit">
            <Edit />
          </IconButton>
          <IconButton onClick={onDelete} aria-label="delete">
            <Delete />
          </IconButton>
        </Box>
      ) : (
        onConnect && (
          <Button variant="contained" color="primary" onClick={onConnect}>
            Connect
          </Button>
        )
      )}
    </Card>
  );
};

export default DoctorCard;
