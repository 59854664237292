// src/components/admin/AdminDashboard.tsx
import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import protectedApi from '../../api/protectedApi';
import { DoctorModel } from '../../types/doctor';
import DoctorCard from '../DoctorCard';
import DoctorForm from './DoctorForm';
import { useAuth } from '../../context/AuthContext';
import { removeToken, removeTokenExpiration } from '../../util/auth';

const CustomBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
});

const PrimaryButton = styled(Button)({
  backgroundColor: '#17858C',
  '&:hover': {
    backgroundColor: '#158389',
  },
  color: '#fff',
  borderColor: '#007bff',
});

const SecondaryButton = styled(Button)({
  backgroundColor: '#122238',
  '&:hover': {
    backgroundColor: '#1B3252',
  },
  color: '#fff',
  borderColor: '#6c757d',
});

const CustomContainer = styled(Container)({
  paddingTop: '20px',
  paddingBottom: '20px',
});

const CustomDoctorCard = styled(DoctorCard)({
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
});

const AdminDashboard: React.FC = () => {
  const [doctors, setDoctors] = useState<DoctorModel[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<DoctorModel | null>(null);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await protectedApi.get('/assistants');
        setDoctors(response.data);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };
    fetchDoctors();
  }, []);

  const addNewDoctor = () => {
    setIsFormOpen(true);
    setSelectedDoctor(null);
  };

  const handleEdit = (doctor: DoctorModel) => {
    setSelectedDoctor(doctor);
    setIsFormOpen(true);
  };

  const handleDelete = async (assistant_id: string) => {
    try {
      await protectedApi.delete(`/assistants/${assistant_id}`, {
        params: {
          "force": false
        }
      });
      setDoctors(doctors.filter(doctor => doctor.assistant_id !== assistant_id));
    } catch (error) {
      console.error('Error deleting doctor:', error);
    }
  };

  const handleFormSubmit = async () => {
    setIsFormOpen(false);
    setSelectedDoctor(null);
    // Refresh the list
    const response = await protectedApi.get('/assistants');
    setDoctors(response.data);
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    removeToken();
    removeTokenExpiration();
    logout();
    navigate('/login');
  };

  return (
    <CustomContainer>
      <CustomBox>
        <Typography variant="h2">Admin Dashboard</Typography>
        <Box>
          <PrimaryButton variant="contained" onClick={addNewDoctor} sx={{ marginRight: '8px' }}>
            Add New Doctor
          </PrimaryButton>
          <SecondaryButton variant="contained" onClick={handleLogout}>
            Logout
          </SecondaryButton>
        </Box>
      </CustomBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={isFormOpen ? 6 : 12}>
          {doctors.map((doctor) => (
            <CustomDoctorCard
              key={doctor.assistant_id}
              image_link={doctor.image_link}
              name={doctor.name}
              speciality={doctor.speciality}
              description={doctor.description}
              onEdit={() => handleEdit(doctor)}
              onDelete={() => handleDelete(doctor.assistant_id)}
              showEditDelete={true}
            />
          ))}
        </Grid>
        {isFormOpen && (
          <Grid item xs={12} md={6} >
            <DoctorForm
              selectedDoctor={selectedDoctor}
              onFormSubmit={handleFormSubmit}
              onClose={() => setIsFormOpen(false)}
            />
          </Grid>
        )}
      </Grid>
    </CustomContainer>
  );
};

export default AdminDashboard;
