// src/components/admin/DoctorForm.tsx
import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import protectedApi from '../../api/protectedApi';  // Ensure this path is correct
import { DoctorModel } from '../../types/doctor';  // Ensure this path is correct

interface DoctorFormProps {
  selectedDoctor: DoctorModel | null;
  onFormSubmit: () => void;
  onClose: () => void;
}

const DoctorForm: React.FC<DoctorFormProps> = ({ selectedDoctor, onFormSubmit, onClose }) => {
  const [formState, setFormState] = useState<DoctorModel>({
    assistant_id: '',
    name: '',
    description: '',
    image_link: '',
    speciality: '',
    initial_dialog: ''
  });

  useEffect(() => {
    if (selectedDoctor) {
      setFormState(selectedDoctor);
    } else {
      setFormState({
        assistant_id: '',
        name: '',
        description: '',
        image_link: '',
        speciality: '',
        initial_dialog: ''
      });
    }
  }, [selectedDoctor]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (selectedDoctor) {
        await protectedApi.patch(`/assistants/${selectedDoctor.assistant_id}`, formState);
      } else {
        await protectedApi.post('/assistants', formState);
      }
      onFormSubmit();
    } catch (error) {
      console.error('Error saving AI Assistant:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        {selectedDoctor ? 'Edit AI Assistant' : 'Add AI Assistant'}
      </Typography>
      <TextField
        label="Assistant ID"
        name="assistant_id"
        value={formState.assistant_id}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        
      />
      <TextField
        label="Name"
        name="name"
        value={formState.name}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Description"
        name="description"
        value={formState.description}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Image Link"
        name="image_link"
        value={formState.image_link}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Speciality"
        name="speciality"
        value={formState.speciality}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Initial Dialog"
        name="initial_dialog"
        value={formState.initial_dialog}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <Box mt={2}>
        <Button type="submit" variant="contained" color="primary" sx={{ mr: 1 }}>
          {selectedDoctor ? 'Update' : 'Create'}
        </Button>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default DoctorForm;
