import React, { ChangeEvent, useState, KeyboardEvent } from 'react';
import { useMediaQuery, Box, Button, Checkbox, Container, FormControlLabel, Grid, Link, TextField, Typography, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import SocialSignUpButtons from './SocialSignUpButtons';
import { UserCreate } from '../types/user';
import { AxiosError } from 'axios';
import { ErrorDetail } from '../types/errorDetail';

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const [usernameError, setUsernameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value.trim());
      if (value.trim()) setUsernameError('');
    } else if (name === 'email') {
      setEmail(value.trim().toLowerCase());
      if (!emailRegex.test(value.trim().toLowerCase())) {
        setEmailError('Invalid email format');
      } else {
        setEmailError('');
      }
    } else if (name === 'password') {
      setPassword(value);
      if (!passwordRegex.test(value)) {
        setPasswordError('Password must be at least 8 characters long and include at least one letter, one number, and one special character');
      } else {
        setPasswordError('');
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleRegister();
    }
  };

  const handleRegister = async () => {
    let hasError = false;
    const trimmedUsername = username.trim();
    const trimmedEmail = email.trim().toLowerCase();

    if (!trimmedUsername) {
      setUsernameError('Username is required');
      hasError = true;
    }
    if (!trimmedEmail) {
      setEmailError('Email is required');
      hasError = true;
    } else if (!emailRegex.test(trimmedEmail)) {
      setEmailError('Invalid email format');
      hasError = true;
    }
    if (!password) {
      setPasswordError('Password is required');
      hasError = true;
    } else if (!passwordRegex.test(password)) {
      setPasswordError('Password must be at least 8 characters long and include at least one letter, one number, and one special character');
      hasError = true;
    }
    if (!checked) {
      setError('You must agree to the terms and privacy policy');
      hasError = true;
    }

    if (hasError) {
      setSnackbarOpen(true);
      return;
    }

    try {
      const user_create: UserCreate = {
        username: trimmedUsername,
        email: trimmedEmail,
        password
      }
      const response = await api.post('/register', user_create);
      console.debug(response.data);
      navigate('/login');
    } catch (error) {
      const axiosError = error as AxiosError<ErrorDetail>;
      console.error('Registration failed:', axiosError);
      if (axiosError.response) {
        setError(axiosError.response.data.detail);
      } 
      else {
        setError("Request failed");
        console.error('No response received', axiosError.request);
      }
      
      setSnackbarOpen(true);
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) setError('');
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="xl" className='main_container' sx={{ display: 'flex', minHeight: '100vh', minWidth: '0' }}>
      <Grid container spacing={isSmallScreen ? 0 : 3} sx={{ flex: 1 }}>
        <Grid item xs={12} md={5} sx={{
          backgroundColor: '#b3dee1',
          display: isSmallScreen ? 'flex' : 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: isSmallScreen ? 2 : 0
        }}>
          <Box 
            className='left_coloumn'
            width="100%" 
            height="100%" 
          >
            <Box
              component="img"
              src="/globifit-logo.svg"
              alt="GlobiFit Logo"
              sx={{ width: isSmallScreen ? '150px' : '250px', marginBottom: '20px' }}
            />
            <Box marginTop="5%" paddingBottom="10%" textAlign={"center"}>
              
              <Typography variant="h4" className='login_main_txt'>
                Worldwide Wellness
              </Typography>
              <Typography className='left_subtitle' variant="subtitle1" sx={{ fontWeight: 'bold', color: '#000000', textAlign: isSmallScreen ? 'center' : 'left' }}>
                Our Global Healthcare Community
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className='right_coloumn' item xs={12} md={7}>
          <Box width="100%" height="20%" className='topholder'>
            <Box width="100%" textAlign={isSmallScreen ? "center" : "right"} mt={2} display="flex" flexDirection="column" height="20%" >
              <Typography variant="body2" className='external_txt'>
                Already a member? <Link href="/login" className='main_link_top'>Sign In</Link>
              </Typography>
            </Box>
          </Box>
          <Box className='login_box'  width={isSmallScreen ? "100%" : "75%"} height="80%">
            <Box textAlign={'center'}>
              <Typography variant="h5" className='main_title' gutterBottom sx={{ fontWeight: 'bold', marginY: '16px', color: '#000000', textAlign: isSmallScreen ? 'center' : 'center' }}>
                Get started with Globi.Fit
              </Typography>
              <Typography className='main_subtitle'>Discover Your Path to Wellness</Typography>
            </Box>
            
            <TextField
              fullWidth
              name="username"
              label="Username"
              variant="outlined"
              margin="normal"
              value={username}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              error={!!usernameError}
              helperText={usernameError}
            />
            <TextField
              fullWidth
              name="email"
              label="Email"
              type="email"
              variant="outlined"
              margin="normal"
              value={email}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              fullWidth
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              margin="normal"
              value={password}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              error={!!passwordError}
              helperText={passwordError}
            />
            <FormControlLabel
            className='check_box'
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  name="termsAndConditions"
                  color="primary"
                />
              }
              label={
                <Typography className='terms_links' variant="body2">
                  I agree to the{' '}
                  <Link href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                    Terms
                  </Link>{' '}
                  and{' '}
                  <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </Link>.
                </Typography>
              }
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <Button
                className='submit_btn'
                variant="contained"
                sx={{ mt: 2, backgroundColor: '#0097a7', color: 'white' }}
                disabled={!checked || usernameError !== '' || emailError !== '' || passwordError !== ''}
                onClick={handleRegister}
              >
                Create Account
              </Button>
              {error && (
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              )}
              <Box textAlign="right">
                <Link href="/forgotPassword" className='forgot_link' variant="body2">
                  Forgot Password?
                </Link>
              </Box>
            </Box>
            <Box my={3}>
              <Typography variant="body2" align="center" className='devider'>
                Or 
              </Typography>
            </Box>
            <Box className='social_links'>
              <SocialSignUpButtons />
            </Box>
          </Box>
          <Box my={3} className='btm_link_box'>
            <Link href="/terms-and-conditions">Terms & Conditions</Link>
            <Link href="/privacy-policy">Privacy Policy</Link>
            </Box>
        </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Register;
